import React from 'react';
import AdminMenu from './AllMenu/AdminMenu';
import UserMenu from './AllMenu/UserMenu';
import SuperAdminMenu from './AllMenu/SuperAdminMenu';
import MenuSTL from './AllMenu/MenuSTL';
import MenuRG from './AllMenu/MenuRG';


export const Menu = ( {profileData} ) => {


 const mail = profileData.profileData.userData.mail ;
 const name = profileData.profileData.userData.displayName
  
 const adminMenuProps = {
  Name: name,
  Mail: mail,
  Ecole : profileData.profileData.urlData.ecole,
  Campus : profileData.profileData.urlData.campus
};

    if( mail.startsWith('jury.') ){
      if( mail.startsWith('jury.supbiotech-stl.')){
        return (<div><MenuSTL  {...adminMenuProps  } /></div>);
      }else {
        return (<div><UserMenu {...adminMenuProps } /></div>);
      }
    }else if ( mail.startsWith('admin.') ){
      if( mail.startsWith('admin.supbiotech-stl.')){
        return (<div><MenuSTL  {...adminMenuProps  } /></div>);
      }else {
        return (<div>< AdminMenu {...adminMenuProps }/></div>); 
        //return (<div>< SuperAdminMenu  {...adminMenuProps }/> </div>);
      }
    }else if (mail.startsWith('adminrg-')){
      return (<div>< MenuRG  {...adminMenuProps }/> </div>);
    } else {
      return (<div>< SuperAdminMenu  {...adminMenuProps }/> </div>);
    }
    // ajouter connexion resultat généraux
};

export default Menu;