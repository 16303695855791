import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, TextField, Dialog, DialogActions,Checkbox, DialogContent, DialogContentText, DialogTitle,  Card, CardContent, InputLabel, Select, MenuItem,Rating} from "@mui/material";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Button from '@mui/material/Button'
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));


const FinPassage = ({profileData, camp, school}) => {


    const [candidats, setCandidats] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedStatut, setSelectedStatut] = useState('');
    const [comment, setComment] = useState('');
    const [selectedNumber, setSelectedNumber] = useState('');   
    const [formData, setFormData] = useState({});
    const [idCandidat, setIdCandidat] = useState(''); 
    const [dateConvocation, setdateConvocation ]= useState("")
    const [criteria, setCriteria] = useState({});
    const [stars, setStarts] = useState('');
    const [noteTotale, setNoteTotale] = useState(0);
    const [ratings, setRatings] = useState([0, 0, 0, 0, 0]);
    const [starRating, setStarRating] = useState(0);

    const adminChange = profileData.profileData.userData.displayName;

    if(camp === 'null' && school === 'null' ){
      var ecole = profileData.profileData.urlData.ecole;
      var campus = profileData.profileData.urlData.campus
    
    }else{
      var campus = camp.toLowerCase();
      var ecole = school.toLowerCase();

    }

    const questions = [
      "Qualité de la présentation et qualité de l'expression",
      "Culture générale métier et du domaine",
      "Connaissance de l'école",
      "Auto-évaluation (travail, implication scolaire...)",
      "Ouverture et engagement / Centres d’intérêt"
    ];
    const optionsNotation = [
      { label: "INSUFFISANT", value: 0.5 },
      { label: "PEU SATISFAISANT", value: 1.5 },
      { label: "CORRECT", value: 2.5 },
      { label: "EXCELLENT", value: 3.5 }
    ];

    const statut = ['','Oui', 'Non'];
  
    useEffect(() => { 
      axios
        .get(process.env.REACT_APP_URL_STRAPI+ecole+'s/', {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN_STRAPI}`,
          },
        })
        .then((response) => {

          const currentDate = new Date().toISOString().split('T')[0]; 

          const filteredNoteCandidats = response.data.data.filter((candidat) => {
          const candidatConvocation = candidat.attributes.convocation.split('T')[0];
          const isNote = candidat.attributes.Statut === 'noté'; 
          const campusPassage = candidat.attributes.campus === campus;
          return candidatConvocation === currentDate && isNote && campusPassage;
        });
          setCandidats(filteredNoteCandidats);
        })
        .catch((error) => {
          console.error('Erreur lors de la récupération des données:', error);
        });
    }, [campus,ecole]);

    const handleQuestionRatingChange = (index, value) => {
        const newRatings = [...ratings];
        newRatings[index] = value;
        setRatings(newRatings);
        const sumRatings = newRatings.reduce((acc, curr) => acc + curr, 0) + (starRating * 0.5);
        setNoteTotale(sumRatings);
    };

    const handleStarRatingChange = (event, newValue) => {
      
        setStarRating(newValue);
        const sumRatings = ratings.reduce((acc, curr) => acc + curr, 0) + (newValue * 0.5);
        setNoteTotale(sumRatings);
    };

    const handleButtonPresent = (candidat) => {

        setIdCandidat(candidat);

        async function fetchData() {
            try {
              await axios.get(process.env.REACT_APP_URL_STRAPI+ecole+'s/'+candidat, {
                headers: {
                  Authorization: `Bearer ${process.env.REACT_APP_TOKEN_STRAPI}`, 
                },
              })
              .then(response => {
                setFormData(response.data.data.attributes);
                setComment(response.data.data.attributes.commentaire)
                setStarts(response.data.data.attributes.Stars)
                setStarRating(response.data.data.attributes.Stars)
                const attributes = response.data.data.attributes;

                const dateObj = new Date(response.data.data.attributes.convocation);

                const jour = ('0' + dateObj.getDate()).slice(-2);
                const mois = ('0' + (dateObj.getMonth() + 1)).slice(-2);
                const annee = dateObj.getFullYear();
                const heure = ('0' + dateObj.getHours()).slice(-2);
                const minute = ('0' + dateObj.getMinutes()).slice(-2);
        
                setdateConvocation(`${jour}/${mois}/${annee} ${heure}:${minute}`);

                setRatings([
                  attributes.OneInsuffisant ? 0.5 : attributes.OnePeuSatisfaisant ? 1.5 : attributes.OneCorrect ? 2.5 : 3.5,
                  attributes.TwoInsuffisant ? 0.5 : attributes.TwoPeuSatisfaisant ? 1.5 : attributes.TwoCorrect ? 2.5 : 3.5,
                  attributes.ThreeInsuffisant ? 0.5 : attributes.ThreePeuSatisfaisant ? 1.5 : attributes.ThreeCorrect ? 2.5 : 3.5,
                  attributes.FourInsufficant ? 0.5 : attributes.FourPeuSatisfaisant ? 1.5 : attributes.FourCorrect ? 2.5 : 3.5,
                  attributes.FiveInsuffisant ? 0.5 : attributes.FivePeuSatisfaisant ? 1.5 : attributes.FiveCorrect ? 2.5 : 3.5
                ]);
                 
                })
                } catch (error) {
                    console.error('Une erreur s\'est produite :', error);
                }
            }
          fetchData();
        setOpenDialog(true);  
    };
    
    const handleCloseDialog = (confirmation) => {

        if(confirmation === 'Annulé'){
            setOpenDialog(false);
        }else if(confirmation === 'Validé'){
          
   
          const newData = {
            data:  {
              CA: selectedStatut !== '' ? selectedStatut : undefined,
              commentaire: comment !== '' ? comment : undefined,
              note: selectedNumber !== '' ? selectedNumber : undefined,
              AdminChangeName : adminChange,
              note : noteTotale,
              Stars: starRating,
              OneInsuffisant: ratings[0] === 0.5,
              OnePeuSatisfaisant: ratings[0] === 1.5,
              OneCorrect: ratings[0] === 2.5,
              OneExcellent: ratings[0] === 3.5,
              TwoInsuffisant: ratings[1] === 0.5,
              TwoPeuSatisfaisant: ratings[1] === 1.5,
              TwoCorrect: ratings[1] === 2.5,
              TwoExcellent: ratings[1] === 3.5,
              ThreeInsuffisant: ratings[2] === 0.5,
              ThreePeuSatisfaisant: ratings[2] === 1.5,
              ThreeCorrect: ratings[2] === 2.5,
              ThreeExcellent: ratings[2] === 3.5,
              FourInsufficant: ratings[3] === 0.5,
              FourPeuSatisfaisant: ratings[3] === 1.5,
              FourCorrect: ratings[3] === 2.5,
              FourExcellent: ratings[3] === 3.5,
              FiveInsuffisant: ratings[4] === 0.5,
              FivePeuSatisfaisant: ratings[4] === 1.5,
              FiveCorrect: ratings[4] === 2.5,
              FiveExcellent: ratings[4] === 3.5
            }     
          };
          const url = process.env.REACT_APP_URL_STRAPI+ecole+'s/'+ idCandidat

          axios.put(url, newData, {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_TOKEN_STRAPI}`,
            },
          })
          .then(response => {
              setOpenDialog(false);
              setSelectedNumber('');
              setSelectedStatut('');
              setComment('');

          })
          .catch(error => {
              console.error('Erreur lors de la requête :', error);
          });
        }
        
    };

    //Focntion qui permet de récupérer les donnnées entré dans la fenetre de dialogue
    const handleNumberChange = (event) => {
      setSelectedNumber(event.target.value);
    };
    const handleStatutChange = (event) => {
      setSelectedStatut(event.target.value);
    };
    const handleCommentChange = (event) => {
      setComment(event.target.value);
  };
     
      //fonction qui récupère l'id de l'etudiant et qui renvoi vers sont livret de motivation 
    const handleSearch = (e) => {
      setSearchTerm(e.target.value);
    };
    //fonction qui permet de filtrer avec la barre de recherche
    const filteredCandidats = candidats.filter((candidat) =>
      candidat.attributes.nom.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  
    return (
      <Container >
        <TableContainer component={Paper} align='center'>
          <Typography component="h1" variant="h5" align="center">
           Liste des candidats notés
          </Typography> 
          <TableRow>
          <TextField  type="text" id="search" label="Search" sx={{ width: 800, mt: 5, mb:5 }}  value={searchTerm} onChange={handleSearch} />
          </TableRow>    
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell >Numéro Parcoursup</StyledTableCell>
                <StyledTableCell  align="center">Civilité</StyledTableCell>
                <StyledTableCell  align="center">Nom du candidat</StyledTableCell>
                <StyledTableCell  align="center">Convocation</StyledTableCell> 
                <StyledTableCell  align="center">Modifier</StyledTableCell> 
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCandidats.map((candidat) => (
                <StyledTableRow key={candidat.attributes.parcoursup}>
                    <StyledTableCell component="th" scope="candidat">{candidat.attributes.parcoursup}</StyledTableCell>
                    <StyledTableCell align="center">{candidat.attributes.civilite}</StyledTableCell>
                    <StyledTableCell align="center">{candidat.attributes.nom} {candidat.attributes.prenom}</StyledTableCell>
                    <StyledTableCell align="center">{candidat.attributes.convocation.split('T')[0]}</StyledTableCell>
                    <StyledTableCell align="center">
                        <Button  onClick={() => handleButtonPresent(candidat.id)}>Modifier</Button> 
                    </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="lg">
            <DialogTitle>Modifier le candidat</DialogTitle>
            <DialogContent>
                <DialogContentText>
                
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <Typography variant="h6" component="div">
                          Détails du candidat
                        </Typography>
                        <Card sx={{ minWidth: 275 }}>
                              <Grid container spacing={1}>
                                <Grid item xs={6}>
                                  <CardContent>
                                    <Typography variant="h6" component="div">
                                    {formData.civilité}  Nom: {formData.nom} {formData.prenom}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Numéro Parcoursup: {formData.parcoursup}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Date de Convocation: {dateConvocation}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Case C.A: {formData.CA === true ? 'Oui': 'Non'}
                                    </Typography>
                                  </CardContent>
                                </Grid>
                                <Grid item xs={6}>
                                  <CardContent>
                                    <Typography variant="body2" color="text.secondary">
                                        Commentaires: {formData.commentaire}
                                    </Typography>
                                    <br></br>
                                    <Typography variant="body2" color="text.secondary">
                                      Point Bonus
                                    </Typography>
                                    <Rating
                                        name="star-rating"
                                        value={stars}
                                        precision={1}
                                    />

                                  </CardContent>
                                </Grid>
                              </Grid>
                          </Card>
                          <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    </Grid>
                    <Box sx={{ width: '100%', mb: 3, mt: 4, pt: 2 }}> 
                        <Divider sx={{ mb: 5 }} /> 
                        <Typography variant="h6" component="div">
                          Modification des Critères
                        </Typography>
                        <Grid container spacing={2}>
                            {questions.map((question, index) => (
                                <Grid container item xs={12} spacing={1} key={index} alignItems="center">
                                    <Grid item xs={6}>
                                        <Typography>{question}</Typography>
                                    </Grid>
                                    {optionsNotation.map((option) => (
                                    <Grid item key={option.label}>
                                        <FormControl component="fieldset">
                                            <Checkbox
                                                checked={ratings[index] === option.value}
                                                onChange={() => handleQuestionRatingChange(index, option.value)}
                                            />
                                            <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>{option.label}</Typography>
                                        </FormControl>
                                    </Grid>
                                    ))}
                                </Grid>
                            ))}
                        </Grid>
                        <Divider sx={{ mb: 5 }} /> 
                    </Box>
                    <Grid item xs={8}>
                      <Card variant="outlined">
                        <CardContent>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>Bonus</Typography>
                            <Rating
                                name="star-rating"
                                value={starRating}
                                precision={1}
                                onChange={handleStarRatingChange}
                            />
                            </Grid>
                            <Grid item xs={6}>
                              <FormControl sx={{ minWidth: 120, width: '100%' }}>
                                <InputLabel>Case C.A</InputLabel>
                                <Select
                                  value={selectedStatut}
                                  onChange={handleStatutChange}
                                  label="Case C.A"
                                >
                                  {statut.map((ca) => (
                                    <MenuItem key={ca} value={ca === 'Oui' ? true : false}>
                                      {ca}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <TextField
                            label="Commentaires"
                            multiline
                            rows={4}
                            value={comment}
                            onChange={handleCommentChange}
                            variant="outlined"
                            fullWidth 
                            sx={{ mt: 2 }}
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </DialogContentText>
                {/* Ajoutez ici votre formulaire avec les champs de modification */}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleCloseDialog('Annulé')} color="primary">
                    Annuler
                </Button>
                <Button onClick={() => handleCloseDialog('Validé')} color="primary">
                    Valider
                </Button>
            </DialogActions>
        </Dialog>
      </Container>
    );
  };
  
  export default FinPassage;
  