import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

import { InfosUserProvider } from './InfosUserContext';

export const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize().then(() => {
  // Permet de vérifier s'il y a déjà un compte authentifié
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }
  // Optionnel - Met à jour le compte si l'utilisateur se connecte depuis un autre onglet ou une autre fenêtre
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  const container = document.getElementById("root");
  const root = ReactDOM.createRoot(container);

  root.render(
    <div>
      <InfosUserProvider>
        <BrowserRouter>
        <App pca={msalInstance} />
        </BrowserRouter>
      </InfosUserProvider>
    </div >
  );
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
