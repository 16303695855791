import React, { useState, useEffect } from 'react';
import { Container } from "@mui/material";
import { styled } from '@mui/material/styles';
import axios from 'axios';
import Box from '@mui/material/Box';
import GroupsIcon from '@mui/icons-material/Groups';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import PDFViews from '../../PDFViews';
import { Grid, CardContent, Typography} from '@mui/material';


const InfoCandidatSTL =  (data) => {
    

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    }));

    const [candidat, setCandidat] = useState([]);
    const [dateConvocation, setdateConvocation ]= useState("")

  useEffect(() => {
    axios.get(process.env.REACT_APP_URL_STRAPI+'stls/'+data.searchID, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN_STRAPI}`, // Remplacez votreToken par votre token d'authentification
        },
      })
      .then(response => {
        setCandidat(response.data.data.attributes);

        const dateObj = new Date(response.data.data.attributes.convocation);
        const jour = ('0' + dateObj.getDate()).slice(-2);
        const mois = ('0' + (dateObj.getMonth() + 1)).slice(-2);
        const annee = dateObj.getFullYear();
        const heure = ('0' + dateObj.getHours()).slice(-2);
        const minute = ('0' + dateObj.getMinutes()).slice(-2);

        setdateConvocation(`${jour}/${mois}/${annee} ${heure}:${minute}`);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des données:', error);
      });
  }, [data.searchID]);

    return (
        
        <Container align="center">
            <Grid container  spacing={3}>       
                <Grid key={candidat.id} item xs={12} sm={6} md={3}>   
                    <CardContent>
                        <Typography variant="h6" component="h2">
                            N° Parcoursup
                        </Typography>
                        <Typography variant="body2" component="p">
                            {candidat.parcoursup}
                        </Typography>
                    </CardContent>
                </Grid>
                <Grid key={candidat.id} item xs={12} sm={6} md={3}>   
                    <CardContent>
                        <Typography variant="h6" component="h2">
                            Candidat
                        </Typography>
                        <Typography variant="body2" component="p">
                        {candidat.civilite} {candidat.prenom} {candidat.nom}
                        </Typography>
                    </CardContent>
                </Grid>
                <Grid key={candidat.id} item xs={12} sm={6} md={3}>   
                    <CardContent>
                        <Typography variant="h6" component="h2">
                            Série
                        </Typography>
                        <Typography variant="body2" component="p">
                            {candidat.Serie}
                        </Typography>
                    </CardContent>
                </Grid>
                <Grid key={candidat.id} item xs={12} sm={6} md={3}>   
                    <CardContent>
                        <Typography variant="h6" component="h2">
                            Convocation
                        </Typography>
                        <Typography variant="body2" component="p">
                            {dateConvocation}
                        </Typography>
                    </CardContent>
                </Grid>
            </Grid>
            
            <Box sx={{ width: '100%' }} >
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={5}>
                        <Item align='left'>Voeux EPITA: {candidat.vepita}</Item>
                    </Grid>
                    <Grid item xs={5}>
                        <Item align='left'>Voeux IPSA: {candidat.vipsa}</Item>
                    </Grid>
                    <Grid item xs={2}>
                        <Link to={candidat.lien_teams} target="_blank">
                            <GroupsIcon />
                        </Link>
                    </Grid>
                    <Grid item xs={5}>
                        <Item align='left'>Voeux SupBiotech : {candidat.vsupbio}</Item>
                    </Grid>
                    <Grid item xs={5}>
                        <Item align='left'>Voeux ESME: {candidat.vesme}</Item>
                    </Grid>
                    <Grid item xs={2}>
                        <PDFViews profileCandidat={{ numero: candidat.parcoursup, nom: candidat.nom }} />
                    </Grid>
                </Grid>
            </Box>
            <br></br>
            <Box sx={{ width: '100%' }} >
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={4}>
                        <Item>Spé A : {candidat.SpeA}</Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item>Spé B : {candidat.SpeB}</Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item>Spé abandonnée en 1ère : {candidat.Spe_abandonne}</Item>
                    </Grid>
                </Grid>
            </Box>
        <br></br>
        </Container>
    );
};

export default InfoCandidatSTL;