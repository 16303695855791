import React, { useState } from 'react';
import { Container, FormHelperText, Rating, Typography } from "@mui/material";
import axios from 'axios';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import { useNavigate, useLocation } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { Grid, TextField, InputLabel, Select, MenuItem, Checkbox, ListItemText, Button } from '@mui/material';

const FormMotivation = ({ profileData }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedStatut, setSelectedStatut] = useState('');
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [questionErrors, setQuestionErrors] = useState([]); 
    const [comment, setComment] = useState('');
    const [error, setError] = useState(false);
    const [noteTotale, setNoteTotale] = useState(0);
    const [ratings, setRatings] = useState([0, 0, 0, 0, 0]);
    const [starRating, setStarRating] = useState(0);

    const navigate = useNavigate();
    const location = useLocation();
    const searchCamp = new URLSearchParams(location.search).get('campus');
    const searchSchool = new URLSearchParams(location.search).get('ecole');
    const searchID = new URLSearchParams(location.search).get('data');

    
    const examinateur = profileData.profileData.userData.displayName;
    console.log(examinateur);
    
    const options = ["EPITA", "EPITA Parcours Accompagné", "EPITA Anglophone", "ESME", "ESME Anglophone", "IPSA", "IPSA Anglophone", "SUPBIOTECH", "SUPBIOTECH Anglophone"];
    const statut = ['Oui', 'Non'];

    var ecole = searchSchool ? searchSchool.toLowerCase() : profileData.profileData.urlData.ecole;
    var campus = searchCamp ? searchCamp.toLowerCase() : profileData.profileData.urlData.campus;

console.log(" Ecole et campus ", ecole , campus);




    const questions = [
        "Qualité de la présentation et qualité de l'expression",
        "Culture générale métier et du domaine",
        "Connaissance de l'école",
        "Auto-évaluation (travail, implication scolaire...)",
        "Ouverture et engagement / Centres d’intérêt"
    ];
    const tooltips = [
        [
            "Discours confus et mal structuré.",
            "Discours parfois clair mais souvent confus. ",
            "Discours clair et structuré. ",
            "Discours très clair et parfaitement structuré. "
        ],
        [
            "Aucune connaissance ou curiosité concernant les domaines des écoles visées. Aucun intérêt apparent pour le métier d'ingénieur. ",
            "Connaissance ou curiosité limitées concernant les domaines des écoles visées. Intérêt pour le métier d'ingénieur peu perceptible. ",
            "Bonne connaissance ou curiosité concernant les domaines des écoles visées. Intérêt pour le métier d'ingénieur manifeste. ",
            "Très bonne connaissance ou curiosité concernant les domaines des écoles visées. Passion évidente pour le métier d'ingénieur. "
        ],
        [
            "Aucune connaissance des programmes, des opportunités internationales ou de la vie associative de l'école. ",
            "Connaissance superficielle des programmes et des opportunités internationales. Peu d'intérêt pour la vie associative. ",
            "Bonne connaissance des programmes et des opportunités internationales. Intérêt manifeste pour la vie associative. ",
            "Excellente connaissance des programmes, des opportunités internationales et de la vie associative. Intérêt et engagement très forts. "
        ],
        [
            "Aucune capacité à s'auto-évaluer de manière réaliste. Faible motivation et implication scolaire. ",
            "Capacité limitée à s'auto-évaluer. Motivation et implication scolaire faibles. ",
            "Capacité à s'auto-évaluer de manière réaliste. Motivation et implication scolaire bonnes. ",
            "Grande capacité à s'auto-évaluer de manière réaliste. Très forte motivation et implication scolaire. "
        ],
        [
            "Aucun engagement dans des activités extrascolaires. Esprit fermé, difficulté à accepter des idées nouvelles. Pas de centres d’intérêt exprimés. ",
            "Engagement limité dans des activités extrascolaires. Esprit peu ouvert, difficultés occasionnelles à accepter des idées nouvelles. Peu de centres d’intérêt. ",
            "Engagement dans des activités extrascolaires. Esprit ouvert, capacité à accepter des idées nouvelles. Quelques centres d’intérêt avérés. ",
            "Fort engagement dans des activités extrascolaires. Grande ouverture d'esprit, excellente capacité à accepter des idées nouvelles. De nombreux centres d’intérêt. "
        ]
    ];


    const optionsNotation = [
        { label: "INSUFFISANT", value: 0.5 },
        { label: "PEU SATISFAISANT", value: 1.5 },
        { label: "CORRECT", value: 2.5 },
        { label: "EXCELLENT", value: 3.5 }
    ];

    const handleSelectChange = (event) => setSelectedOptions(event.target.value);

    const handleStatutChange = (event) => setSelectedStatut(event.target.value);

    const handleCommentChange = (event) => setComment(event.target.value);

    const handleConfirmation = (proceed) => {
        setOpenConfirmation(false);
        if (proceed) {
            const sendStatut = {
                data: {
                    Statut: null,
                    ClosingLivret: new Date(),
                    UserClickAnnulation: examinateur
                }
            };

            console.log(process.env.REACT_APP_URL_STRAPI + ecole + 's/' + searchID);
            
            axios.put(process.env.REACT_APP_URL_STRAPI + ecole + 's/' + searchID, sendStatut, {
                headers: { Authorization: `Bearer ${process.env.REACT_APP_TOKEN_STRAPI}` },
            }).then(() => {
                navigate(`/candidats?campus=${encodeURIComponent(campus)}&ecole=${encodeURIComponent(ecole)}`);
            }).catch(error => console.error('Erreur lors de la requête :', error));
        }
    };

    const handleAnnulationButton = () => setOpenConfirmation(true);

    const handleQuestionRatingChange = (index, value) => {

        setQuestionErrors('')
        const newRatings = [...ratings];
        newRatings[index] = value;
        setRatings(newRatings);
        const sumRatings = newRatings.reduce((acc, curr) => acc + curr, 0) + (starRating * 0.5);
        setNoteTotale(sumRatings);
    };

    const handleStarRatingChange = (event, newValue) => {
        setStarRating(newValue);
        console.log(newValue);
        const sumRatings = ratings.reduce((acc, curr) => acc + curr, 0) + (newValue * 0.5);
        console.log(sumRatings);
        
        setNoteTotale(sumRatings);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const errors = questions.map((_, index) => ratings[index] === 0);
        if (errors.includes(true)) {
            setQuestionErrors(errors);
        }

        if (!selectedOptions.length || !selectedStatut || !comment) {
            setError(true);
            return;
        }

       
        const newData = {
            data: {
                Examinateur: examinateur,
                ClosingLivret: new Date(),
                UserClickValidation: examinateur,
                ecole_choix: selectedOptions.toString(),
                CA: selectedStatut === 'Oui' ? true : false,
                commentaire: comment,
                Statut: 'noté',
                note : noteTotale,
                Stars: starRating,
                OneInsuffisant: ratings[0] === 0.5,
                OnePeuSatisfaisant: ratings[0] === 1.5,
                OneCorrect: ratings[0] === 2.5,
                OneExcellent: ratings[0] === 3.5,
                TwoInsuffisant: ratings[1] === 0.5,
                TwoPeuSatisfaisant: ratings[1] === 1.5,
                TwoCorrect: ratings[1] === 2.5,
                TwoExcellent: ratings[1] === 3.5,
                ThreeInsuffisant: ratings[2] === 0.5,
                ThreePeuSatisfaisant: ratings[2] === 1.5,
                ThreeCorrect: ratings[2] === 2.5,
                ThreeExcellent: ratings[2] === 3.5,
                FourInsufficant: ratings[3] === 0.5,
                FourPeuSatisfaisant: ratings[3] === 1.5,
                FourCorrect: ratings[3] === 2.5,
                FourExcellent: ratings[3] === 3.5,
                FiveInsuffisant: ratings[4] === 0.5,
                FivePeuSatisfaisant: ratings[4] === 1.5,
                FiveCorrect: ratings[4] === 2.5,
                FiveExcellent: ratings[4] === 3.5
            }
        };
        const url = process.env.REACT_APP_URL_STRAPI + ecole + 's/' + searchID;
        axios.put(url, newData, {
            headers: { Authorization: `Bearer ${process.env.REACT_APP_TOKEN_STRAPI}` },
        }).then(() => {
            navigate(`/candidats?campus=${encodeURIComponent(campus)}&ecole=${encodeURIComponent(ecole)}`);
        }).catch(error => console.error('Erreur lors de la requête :', error));
    };

    return (
        <Container align="center">
            <Box sx={{ width: '100%', mb: 3, mt: 4, pt: 2 }}> 
                <Divider sx={{ mb: 5 }} /> 
                <Typography variant="h5">
                    CRITÈRES D'ÉVALUATION<span style={{ color: 'red' }}>*</span>
                </Typography>
                <Grid container spacing={0.5}>
                    {questions.map((question, index) => (
                        <Grid container item xs={12} spacing={0.4} key={index} alignItems="center">
                            <Grid item xs={6}>
                                <Typography>{question}</Typography>
                            </Grid>
                            {optionsNotation.map((option,optionIndex) => (
                            <Grid item sx={{ px: 7 }} key={option.label}>
                                    <FormControl component="fieldset">
                                        <Tooltip title={tooltips[index][optionIndex]} placement="top" arrow>
                                            <Checkbox
                                                checked={ratings[index] === option.value}
                                                onChange={() => handleQuestionRatingChange(index, option.value)}
                                            />
                                        </Tooltip>
                                        <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>{option.label}</Typography>
                                    </FormControl>
                                </Grid>
                            ))}
                            {questionErrors[index] && (
                                <FormHelperText error sx={{ marginTop: '-1rem', marginBottom: '1rem' }}>
                                    Veuillez sélectionner une notation pour cette question.
                                </FormHelperText>
                            )}
                        </Grid>
                    ))}
                </Grid>
                <br></br>
                <Typography variant="h6" gutterBottom>Bonus Facultatif (0 à 5 étoiles)</Typography>
                <Rating
                    name="star-rating"
                    value={starRating}
                    precision={1}
                    onChange={handleStarRatingChange}
                />
                <Divider sx={{ mb: 5 }} /> 
            </Box>
            <Box sx={{ width: '100%' }} >
                <Grid container rowSpacing={1}>
                    <Grid item xs={8}>
                        <FormControl required sx={{ m: 1, minWidth: 700 }}>
                            <InputLabel>Quelles écoles au sein du Concours Advance souhaitez-vous présenter ?</InputLabel>
                            <Select
                                multiple
                                label="Quelles écoles au sein du Concours Advance souhaitez-vous présenter ?"
                                value={selectedOptions}
                                onChange={handleSelectChange}
                                renderValue={(selected) => selected.join(', ')}
                                error={error && !selectedOptions.length} 
                            >
                                {options.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        <Checkbox checked={selectedOptions.includes(option)} />
                                        <ListItemText primary={option} />
                                    </MenuItem>
                                ))}
                            </Select>
                            {error && !selectedOptions.length && <FormHelperText>Ce champ est obligatoire</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <Tooltip title="Sélectionnez Oui si le dossier du candidat nécessite une attention particulière de la Commission d'Admission" placement="top">
                            <FormControl required sx={{ minWidth: 120 }}>
                                <InputLabel>Case C.A</InputLabel>
                                <Select
                                    value={selectedStatut}
                                    onChange={handleStatutChange}
                                    label="Case C.A"
                                    error={error && !selectedStatut}
                                >
                                    {statut.map((ca) => (
                                        <MenuItem key={ca} value={ca}>
                                            {ca}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {error && !selectedStatut && <FormHelperText>Ce champ est obligatoire</FormHelperText>}
                            </FormControl>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Box>

            {/* Commentaires */}
            <Tooltip title="Veuillez détailler votre commentaire autant que possible. Si vous cochez 'Oui'à la case C.A, veillez à bien justifier votre choix." placement="top">
            <TextField
                label="Commentaires*"
                multiline
                rows={12}
                value={comment}
                onChange={handleCommentChange}
                variant="outlined"
                fullWidth
                error={error && !comment}
            />
            </Tooltip>
            {error && !comment && <FormHelperText>Ce champ est obligatoire</FormHelperText>}

        
            <Box>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6}>
                            <Button onClick={handleAnnulationButton}  color="error" variant="outlined" sx={{ mt: 2 }}>
                                Annuler
                            </Button>
                            <Dialog open={openConfirmation} onClose={() => handleConfirmation(false)}>
                                <DialogTitle>ATTENTION</DialogTitle>
                                <DialogContent>
                                    <p>Êtes-vous sûr de vouloir annuler ?</p>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => handleConfirmation(false)} color="primary">
                                        Non
                                    </Button>
                                    <Button onClick={() => handleConfirmation(true)} color="primary">
                                        Oui
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Grid>
                        <Grid item xs={6}>
                            <Button type="submit" onClick={handleSubmit} variant="contained" color="primary" sx={{ mt: 2 }}>
                                Valider
                            </Button>
                        </Grid>

                </Grid>
            </Box>
        </Container>
    );
};

export default FormMotivation;
