import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logoEvalAdvance from "../image/Eval_Advance.png";

const defaultTheme = createTheme();

function Connexion() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        {/* Texte au-dessus */}
        <Typography variant="h4" align="center" mb={2}>
          Bienvenue sur le portail Eval Advance
        </Typography>

        {/* Logo dans un cercle (plus grand) */}
        <Box
          sx={{
            width: 300, // Augmentation de la taille
            height: 300,
            borderRadius: "50%",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "3px solid #000", // Optionnel : bordure plus visible
          }}
        >
          <img src={logoEvalAdvance} alt="Logo Concours Advance" style={{ width: "100%", height: "100%" }} />
        </Box>

        {/* Texte d'accueil en dessous */}
        <Typography variant="body1" align="center" mt={2}>
          Merci de visiter notre portail. Nous sommes ravis de vous accueillir !
        </Typography>
      </Box>
    </ThemeProvider>
  );
}

export default Connexion;
