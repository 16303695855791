import React, {useEffect, useState } from 'react';
import axios from 'axios';
import Error from '../views/Error';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button'
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Dialog, DialogActions, DialogContent, DialogTitle, Card, CardContent} from "@mui/material";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container, TextField } from "@mui/material";
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  }));


function ResultatGeneraux ({profileData}) {

    const [EcoleIPSA, setEcoleIPSA] = useState([]);
    const [EcoleESME, setEcoleESME] = useState([]);
    const [EcoleEPITA, setEcoleEPITA] = useState([]);
    const [EcoleSUPBIO, setEcoleSUPBIO] = useState([]);
    const [EcoleSTL, setEcoleSTL] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [combinedData, setcombinedData] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [formData, setFormData] = useState({});
    const [openDialogStl, setOpenDialogStl] = useState(false);
    const [criteria, setCriteria] = useState({});
    const [stars, setStarts] = useState('');



    var mail = profileData.profileData.userData.mail ;
    const containsAdminrg = mail.includes('adminrg-');
    const containsSuperAdmin = mail.includes('superadmin-adv');


    const resources = [
        { key: 'ecoleIPSA', url: `${process.env.REACT_APP_URL_STRAPI}ipsas/?populate=*` },
        { key: 'ecoleESME', url: `${process.env.REACT_APP_URL_STRAPI}esmes/?populate=*` },
        { key: 'ecoleEPITA', url: `${process.env.REACT_APP_URL_STRAPI}epitas/?populate=*` },
        { key: 'ecoleSUPBIO', url: `${process.env.REACT_APP_URL_STRAPI}supbiotechs/?populate=*` },
        { key: 'ecoleSTL', url: `${process.env.REACT_APP_URL_STRAPI}stls/?populate=*` }, 
    ];

    async function fetchDataForAllEcoles() {
        try {
            const promises = resources.map(({ key, url }) =>
                axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_TOKEN_STRAPI}`,
                    },
                })
            );
    
            const responses = await Promise.all(promises);
    
            const dataByEcole = responses.map((response, index) => ({
                key: resources[index].key,
                data: response.data.data,
            }));
    
            const combinedData = dataByEcole.reduce((acc, { key, data }) => {
                switch (key) {
                    case 'ecoleIPSA':
                        acc.ecoleIPSA = data;
                        break;
                    case 'ecoleESME':
                        acc.ecoleESME = data;
                        break;
                    case 'ecoleEPITA':
                        acc.ecoleEPITA = data;
                        break;
                    case 'ecoleSUPBIO':
                        acc.ecoleSUPBIO = data;
                        break;
                    case 'ecoleSTL':
                        acc.ecoleSTL = data;
                        break;
                    default:
                        break;
                }
                return acc;
            }, {});
    
            setEcoleIPSA(combinedData.ecoleIPSA || []);
            setEcoleESME(combinedData.ecoleESME || []);
            setEcoleEPITA(combinedData.ecoleEPITA || []);
            setEcoleSUPBIO(combinedData.ecoleSUPBIO || []);
            setEcoleSTL(combinedData.ecoleSTL || []);
    
            const allCombinedData = [
                ...(combinedData.ecoleIPSA || []),
                ...(combinedData.ecoleESME || []),
                ...(combinedData.ecoleEPITA || []),
                ...(combinedData.ecoleSUPBIO || []),
                ...(combinedData.ecoleSTL || []),
            ];
    
            setcombinedData(allCombinedData);
        } catch (error) {
            console.error('Une erreur s\'est produite lors du chargement des données :', error);
        }
    }
    
    useEffect(() => {
        fetchDataForAllEcoles();
    }, [],);
    


    function handleButtonOpen(idCandidat, ecole){
          

        async function fetchData() {
            try {
              await axios.get(process.env.REACT_APP_URL_STRAPI+ecole.toLowerCase()+'s/'+idCandidat, {
                headers: {
                  Authorization: `Bearer ${process.env.REACT_APP_TOKEN_STRAPI}`, // Remplacez votreToken par votre token d'authentification
                },
              })
              .then(response => {
                setFormData(response.data.data.attributes);
                setStarts(response.data.data.attributes.Stars);
                console.log(response.data.data.attributes);
                

                const criteriaKeys = [
                    'OneInsuffisant', 'OnePeuSatisfaisant', 'OneCorrect', 'OneExcellent',
                    'TwoInsuffisant', 'TwoPeuSatisfaisant', 'TwoCorrect', 'TwoExcellent',
                    'ThreeInsuffisant', 'ThreePeuSatisfaisant', 'ThreeCorrect', 'ThreeExcellent',
                    'FourInsufficant', 'FourPeuSatisfaisant', 'FourCorrect', 'FourExcellent',
                    'FiveInsuffisant', 'FivePeuSatisfaisant', 'FiveCorrect', 'FiveExcellent'
                ];

                const questions = [
                    "Qualité de la présentation et qualité de l'expression",
                    "Culture générale métier et du domaine",
                    "Connaissance de l'école",
                    "Auto-évaluation (travail, implication scolaire...)",
                    "Ouverture et engagement / Centres d’intérêt"
                ];
                
                const selectedCriteria = {};
                criteriaKeys.forEach(key => {
                  if (response.data.data.attributes[key]) selectedCriteria[key] = true;
                });
                setCriteria(selectedCriteria);

              })
                } catch (error) {
                    console.error('Une erreur s\'est produite :', error);
                }
            }

            fetchData();
            if(ecole === 'Stl'){
                setOpenDialogStl(true)
            }else{
                setOpenDialog(true);  
            }
  
    }

    const handleCloseDialog= () => {
        setOpenDialog(false);
        setOpenDialogStl(false)
    }


    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredData = combinedData.filter((candidat) =>

        candidat.attributes.parcoursup.includes(searchTerm) ||
        candidat.attributes.nom.toLowerCase().includes(searchTerm.toLowerCase())

    );

    if (containsAdminrg || containsSuperAdmin) {
        
        return (
            <Container >
            <div style={{ margin: '80px' }}></div> 
            <TableContainer component={Paper} align='center'>
                <Typography component="h1" variant="h5" align="center">
                        Résultats Généraux
                </Typography> 
                <TableRow>
                    <TextField  type="text" id="search" label="Recherche par numéro parcoursup ou nom de famille" sx={{ width: 800, mt: 5, mb:5 }}  value={searchTerm} onChange={handleSearch} />
                </TableRow>    
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell >Parcoursup</StyledTableCell>
                            <StyledTableCell  align="center">Nom du candidat</StyledTableCell>
                            <StyledTableCell  align="center">Ecole</StyledTableCell>
                            <StyledTableCell  align="center">Campus</StyledTableCell>
                            <StyledTableCell  align="center">Livret</StyledTableCell> 
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData.map((candidat) => (
                            <StyledTableRow key={candidat.attributes.parcoursup}>
                                <StyledTableCell component="th" scope="candidat">{candidat.attributes.parcoursup}</StyledTableCell>
                                <StyledTableCell align="center">{candidat.attributes.nom}  {candidat.attributes.prenom} </StyledTableCell>
                                <StyledTableCell align="center">{candidat.attributes.Ecole}</StyledTableCell>
                                <StyledTableCell align="center">{candidat.attributes.campus}</StyledTableCell>  
                                <StyledTableCell align="center">
                                    <ButtonGroup  variant="contained">
                                        <Button  onClick={() => handleButtonOpen(candidat.id,candidat.attributes.Ecole)}>Ouvrir</Button>
                                    </ButtonGroup>
                                </StyledTableCell>
                            </StyledTableRow>                      
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="lg">
                <DialogTitle align="center">Livret du candidat {formData.Classe ? 'Grand Classé' : ''} </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} justifyContent="center" alignItems="center"  >
                        <Grid item xs={12} align="center" style= {{backgroundColor : 'blue'}}>
                            <Typography variant="h6" component="div"  style= {{color : 'white'}} >
                             {formData.parcoursup} - {formData.nom} {formData.prenom} - {formData.Serie} - {formData.Ecole} - {formData.campus}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Card variant="outlined">
                                <CardContent style={{ textAlign: 'justify' }}>
                                    <Typography color="text">
                                        Examinateur : {formData.Examinateur}
                                    </Typography>
                                    <Typography color="text">
                                        Note en motivation: {formData.note}
                                    </Typography>
                                    {formData.Classe === false ? (
                                    <>
                                        <Typography  >
                                        Note en anglais: {formData.noteAnglais === 101 ? 'absent' :  formData.noteAnglais}
                                        </Typography>
                                        <Typography >
                                        Note en {formData.noteMath === 101 ? 'Math : absent' :  formData.ChampSuppl1 + ":" + formData.noteMath}
                                        </Typography>
                                    </>
                                    ):(   
                                        <Typography  >
                                        Grand Classé: OUI
                                        </Typography>
                                    )} 
                                    <Typography >
                                        Case C.A: {formData.CA ? 'Oui' : 'Non'}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid container spacing={2} justifyContent="center">
                        <Typography >
                            Critère de Notation
                        </Typography>
                            {Object.keys(criteria).map((key) => (
                            <Grid item key={key} sx={{ textAlign: 'center' }}>
                                <h4
                                variant="outlined"
                                color={criteria[key] ? 'primary' : 'default'}
                                onClick={() => setCriteria({ ...criteria, [key]: !criteria[key] })}
                                >
                                {key}
                                </h4>
                            </Grid>
                            ))}
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <Card variant="outlined">
                                <Typography style={{ textAlign: 'center', fontSize: 20  }} >
                                Commentaires :
                                </Typography>
                                <Typography style={{ textAlign: 'inherit' }} >
                                    {formData.commentaire}
                                </Typography>
                            </Card>
                            <Card variant="outlined">
                                <Typography style={{ textAlign: 'center'}} >
                                Préférence du candidat : {formData.ecole_choix}
                                </Typography>
                            </Card>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>




            <Dialog open={openDialogStl} onClose={handleCloseDialog} fullWidth maxWidth="lg">
                <DialogContent>

                    <Grid container spacing={2} justifyContent="center" alignItems="center">  
                        <Grid item xs={12} align="center">
                            <DialogTitle align="center">Livret du candidat</DialogTitle>
                            <Typography variant="h4" component="div">
                                {formData.parcoursup} - {formData.nom} {formData.prenom} - {formData.Serie} - Supbiotech/{formData.campus}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} align="center" style= {{backgroundColor : 'blue'}} mt={6}>
                            <Typography variant="h5" component="div" style= {{color : 'White'}}>
                                Motivation
                            </Typography>
                        </Grid>
                            <Grid item xs={6} md={4}>
                                <Card variant="outlined">
                                    <CardContent style={{ textAlign: 'center' }}>
                                        <Typography color="text">
                                            Examinateur : {formData.Examinateur}
                                        </Typography>
                                        <Typography color="text">
                                            Note : {formData.note}
                                        </Typography>
                                        <Typography >
                                            Case C.A: {formData.CA ? 'Oui' : 'Non'}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={6} md={8}>
                                <Card variant="outlined">
                                    <Typography style={{ textAlign: 'center', fontSize: 20  }} >
                                    Commentaires :
                                    </Typography>
                                    <Typography  >
                                        {formData.commentaire}
                                    </Typography>
                                    <Card variant="outlined" >
                                        <Typography style={{ textAlign: 'center'}}  >
                                        Préférence du candidat : {formData.ecole_choix}
                                        </Typography>
                                    </Card>
                                </Card>
                            </Grid>

                            <Grid item xs={12} align="center"  style= {{backgroundColor : 'blue'}} mt={6}>
                            <Typography variant="h5" component="div"  style= {{color : 'White'}}>
                                Anglais
                            </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Card variant="outlined">
                                    <CardContent style={{ textAlign: 'center' }}>
                                        <Typography color="text">
                                            Examinateur : {formData.ExaminateurAnglais}
                                        </Typography>
                                        <Typography  >
                                            Note : {formData.noteAnglais}
                                        </Typography>
                                        <Typography  >
                                            Sujet choisi: {formData.Type_sujet}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={6} md={8}>
                                <Card variant="outlined">
                                    <Typography style={{ textAlign: 'center', fontSize: 20  }} >
                                    Commentaires  :
                                    </Typography>
                                    <Typography >
                                        {formData.commentaireAnglais}
                                    </Typography>
                                    <Card variant="outlined">
                                        <Box sx={{ flexGrow: 1 }}>
                                            <Grid container spacing={1}>
                                                <Grid container item spacing={3}>
                                                <Grid item xs={4}>
                                                    Classe Européenne suivie : {formData.Classe_europeenne ? 'Oui' : 'Non'} 
                                                </Grid>
                                                <Grid item xs={4}>
                                                    Parents bilingues : {formData.parent_bilingue ? 'Oui' : 'Non'}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    Séjours linguistiques : {formData.Sejour_linguistique ? 'Oui' : 'Non'}
                                                </Grid>
                                                </Grid>
                                                <Grid container item spacing={3}>
                                                <Grid item xs={4}>
                                                    Parents bilingues : {formData.parent_bilingue ? 'Oui' : 'Non'} 
                                                </Grid>
                                                <Grid item xs={4}>
                                                    Séries/films en anglais : {formData.Serie_anglais ? 'Oui' : 'Non'}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    Lit des livres en anglais : {formData.livre_anglais ? 'Oui' : 'Non'}
                                                </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Card>
                                </Card>
                            </Grid>
                            <Grid item xs={12} align="center"  style= {{backgroundColor : 'blue'}} mt={6}>
                            <Typography variant="h5" component="div"  style= {{color : 'White'}}>
                                Mathématique
                            </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Card variant="outlined">
                                    <CardContent style={{ textAlign: 'center' }}>          
                                        <Typography color="text">
                                            Examinateur : {formData.ExaminateurMath}
                                        </Typography>
                                        <Typography >
                                            Note  : {formData.noteMath}
                                        </Typography>
                                    </CardContent>
                                </Card>      
                            </Grid>
                            <Grid item xs={6} md={8}>
                                <Card variant="outlined">
                                    <Typography style={{ textAlign: 'center', fontSize: 20  }} >
                                    Commentaires  :
                                    </Typography>
                                    <Typography >
                                        {formData.commentaireMath}
                                    </Typography>
                                    <Card variant="outlined">
                                        <Box sx={{ flexGrow: 1 }}>
                                            <Grid container spacing={1}>
                                                <Grid container item spacing={3}>
                                                    <Grid item xs={4}>
                                                        Note Exercice 1 : {formData.note_Math_ex1} 
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        Difficulté de l'exercice 1 : {formData.niveauEx1Math}
                                                    </Grid>
                                                </Grid>
                                                <Grid container item spacing={3}>
                                                    <Grid item xs={4}>
                                                       Note Exercice 2  : {formData.note_Math_ex2} 
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        Difficulté de l'exercice 2  : {formData.niveauEx2Math}
                                                    </Grid>
                                                </Grid>
                                                <Grid container item spacing={3}>
                                                    <Grid item xs={8}>
                                                       Point Bonus Rapidité ? (+1 point)  :  {formData.Bonus_rapidite ? 'Oui' : 'Non'} 
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Card>
                                </Card>
                            </Grid>
                            <Grid item xs={12} align="center"   style= {{backgroundColor : 'blue'}} mt={6}>
                            <Typography variant="h5" component="div"  style= {{color : 'White'}}>
                                Biologie
                            </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Card variant="outlined">
                                    <CardContent style={{ textAlign: 'center' }}>
                                        <Typography color="text">
                                            Examinateur : {formData.ExaminateurBiologie}
                                        </Typography>
                                        <Typography >
                                            Note : {formData.noteBiologie}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={6} md={8}>
                                <Card variant="outlined">
                                    <Typography style={{ textAlign: 'center', fontSize: 20  }} >
                                    Commentaires :
                                    </Typography>
                                    <Typography >
                                        {formData.commentaireBio}
                                    </Typography>
                                    <Card variant="outlined">
                                        <Box sx={{ flexGrow: 1 }}>
                                            <Grid container spacing={1}>
                                                <Grid container item spacing={3}>
                                                    <Grid item xs={4}>
                                                        Note Exercice 1 : {formData.note_Bio_ex1} 
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        Difficulté de l'exercice 1 : {formData.niveauEx1Biologie}
                                                    </Grid>
                                                </Grid>
                                                <Grid container item spacing={3}>
                                                    <Grid item xs={4}>
                                                       Note Exercice 2  : {formData.note_Bio_ex2} 
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        Difficulté de l'exercice 2  : {formData.niveauEx2Biologie}
                                                    </Grid>
                                                </Grid>
                                                <Grid container item spacing={3}>
                                                    <Grid item xs={8}>
                                                       Niveau Ingénieur ?  :  {formData.Niveau_ingenieur ? 'Oui' : 'Non'} 
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Card>
                                </Card>
                            </Grid>
                    </Grid>      
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
          </Container>
        );

    } else {
        return <Error />
    }

}
export default ResultatGeneraux;