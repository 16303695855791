import React from 'react';

const PDFViews = ({profileCandidat}) =>{

    const nom =  profileCandidat?.nom ? profileCandidat.nom.replace(/\s+/g, '') : ""; // l'utilisation de  ?.  permet de vérifier si candidat et nom sont définis avant d'accéder à la propriéré nom.
    const NumParcoursup = profileCandidat.numero
    
  return (
    <a href={`https://ionisgroupcom.sharepoint.com/:b:/r/sites/OrauxADVANCEEPITAParis/Rootroot/${NumParcoursup}-${nom}.pdf?csf=1&web=1&e=F56fqf`}  target="_blank" rel="noopener noreferrer">
      Dossier Parcoursup
    </a>
  );
}

export default PDFViews;

// import React from 'react';
// import { Viewer, Worker } from '@react-pdf-viewer/core'; // Le composant Viewer
// import '@react-pdf-viewer/core/lib/styles/index.css'; // Les styles CSS par défaut
// import '@react-pdf-viewer/default-layout/lib/styles/index.css'; // Les styles pour la mise en page par défaut

// // Plugins pour désactiver certaines options
// import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
// import { getFilePlugin } from '@react-pdf-viewer/get-file';

// const PDFViews = ({ profileCandidat }) => {
//     // Plugin toolbar personnalisé
//     const nom =  profileCandidat?.nom ? profileCandidat.nom.replace(/\s+/g, '') : ""; // l'utilisation de  ?.  permet de vérifier si candidat et nom sont définis avant d'accéder à la propriéré nom.
//     const NumParcoursup = profileCandidat.numero
//     const toolbar = toolbarPlugin();
//     const getFile = getFilePlugin();
//     console.log(`https://ionisgroupcom.sharepoint.com/:b:/r/sites/OrauxADVANCEEPITAParis/Rootroot/${NumParcoursup}-${nom}.pdf?csf=1&web=1&e=F56fqf`);
    

//     return (
//         <div style={{ height: '750px' }}>
            
//             <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
               
//                 <Viewer
//                     fileUrl={`https://ionisgroupcom.sharepoint.com/:b:/r/sites/OrauxADVANCEEPITAParis/Rootroot/${NumParcoursup}-${nom}.pdf?csf=1&web=1&e=F56fqf`} // URL du fichier PDF
//                     plugins={[
//                         toolbar, // Barre d'outils
//                         getFile, // Plugin pour désactiver le téléchargement
//                     ]}
//                 />
//             </Worker>
//         </div>
//     );
// };

// export default PDFViews;
